//@flow
import React from 'react'
import PropTypes from 'prop-types'
import {AppContext} from "../../AppContext";
import CircularProgress from "@material-ui/core/CircularProgress";
import MealList from "../MealList/MealList";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import ListEmptyMessage from "../ListEmptyMessage/ListEmptyMessage";
import Meal from "../../domain/Meal";
import {withStyles} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import green from "@material-ui/core/colors/green";

const styles = () => ({
    progress: {
        textAlign: 'center',
        marginTop: 50
    },
    buttonAdd: {
        color: green[500],
        left: '50%',
        marginLeft: '-55px',
        marginTop: 20
    }
});

class MyMeals extends React.Component {

    static contextType = AppContext;

    static propTypes = {
        onSelectMeal: PropTypes.func,
        onNewMenuItem: PropTypes.func,
        onMealDeleted: PropTypes.func,
        focus: PropTypes.bool,
    };

    state = {
        showProgress: false,
        myItems: null,
        myItemsError: false,
        myItemsLoaded: false
    };

    shouldComponentUpdate(nextProps: Readonly<P>, nextState: Readonly<S>, nextContext: any): boolean {
        return nextProps.focus;
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS): void {
        this.loadMyItems();
    }

    loadMyItems = () => {
        if (this.state.myItemsLoaded)
            return;

        this.setState(
            {...this.state, showProgress: true, myItemsLoaded: true},
            () => {
                // noinspection JSDeprecatedSymbols
                this.context.mealdb.getOwnMeals(300, 0)
                    .then(result => {
                        this.setState({
                            ...this.state,
                            myItemsError: false,
                            showProgress: false,
                            myItems: result
                        })
                    })
                    .catch(() => {
                        this.setState({
                            ...this.state,
                            showProgress: false,
                            myItemsError: true
                        })
                    })
            }
        );
    };

    handleMealSelect = (el : Meal) => {
        const {onSelectMeal} = this.props;
        if (!onSelectMeal)
            return;

        onSelectMeal(el)
    };

    handleMealDelete = (el : Meal) => {
        this.setState(
            {...this.state, showProgress: true, myItemsLoaded: true},
            () => {
                // noinspection JSDeprecatedSymbols
                this.context.mealdb.deleteOwnMeals([el.id], 100, 0)
                    .then(result => {
                        this.setState(
                            {
                                ...this.state,
                                myItemsError: false,
                                showProgress: false,
                                myItems: result
                            },
                            () => {
                                const {onMealDeleted} = this.props
                                if (onMealDeleted)
                                    onMealDeleted();
                            }
                        )
                    })
                    .catch(() => {
                        this.setState({
                            ...this.state,
                            showProgress: false,
                            myItemsLoaded: false
                        })
                    })
            }
        );
    };

    handleNewMenuItem = () => {
        if (this.props.onNewMenuItem)
            this.props.onNewMenuItem();
    };

    render() {
        const {showProgress} = this.state;
        const {myItemsError} = this.state;
        const classes = this.props.classes;

        const myItems : Array<Meal> = this.state.myItems;
        const myItemsEmpty = !myItems || myItems.length === 0;

        return (
            <div>
                {showProgress ? (
                    <div className={classes.progress}>
                        <CircularProgress/>
                    </div>
                ) : (
                    <div>
                        {!myItemsEmpty && !myItemsError &&
                        <div>
                            <MealList meals={myItems} onSelectMeal={this.handleMealSelect} onDeleteMeal={this.handleMealDelete}/>
                            <Button variant="outlined" onClick={this.handleNewMenuItem} className={classes.buttonAdd}>
                                Добавить
                            </Button>
                        </div>
                        }

                        {myItemsEmpty && !myItemsError &&
                        <div>
                            <ListEmptyMessage text="Список пуст"/>
                            <Button variant="outlined" onClick={this.handleNewMenuItem} className={classes.buttonAdd}>
                                Добавить
                            </Button>
                        </div>
                        }

                        {myItemsError &&
                        <ErrorMessage text="Ошибка загрузки списка. Пожалуйста, повторите попытку."/>
                        }
                    </div>
                )}
            </div>
        )
    }
}

export default withStyles(styles)(MyMeals);